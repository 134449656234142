import { Badge, Button, Table, useBooleanState } from 'akeneo-design-system';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SynchronizationScopeLabel } from '../../component/SynchronizationScopeLabel';
import { SynchronizationTypeLabel } from '../../component/SynchronizationTypeLabel';
import { Synchronization } from '../../model/Synchronization';
import { SynchronizationStatus } from '../../model/SynchronizationStatus';
import { SynchronizationType } from '../../model/SynchronizationType';
import { SynchronizationDuration } from './SynchronizationDuration';
import { SynchronizationStatusBadge } from './SynchronizationStatusBadge';
import { SynchronizationScopeIcon } from './SynchronizationScopeIcon';
import { SynchronizationModal } from './SynchronizationModal';
import { SynchronizationScope } from '../../model/SynchronizationScope';
import { ScheduledSyncConfigModal } from './ScheduledSyncConfigModal';
import { FilterPimAttribute } from '../../model/FilterPimAttribute';
import { useFeatureFlags } from '../../../../util/featureFlag';
import { useSelector } from '../../store/StoreProvider';

const IconContainer = styled.div`
    align-items: center;
    display: flex;
    height: 44px;
    padding-right: 20px;
`;

const ButtonContainer = styled.div`
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const ButtonStyled = styled(Button)`
    margin-left: 17px;
    min-width: 100px;
    display: flex;
    justify-content: center;
`;

type Props = {
    synchronization: Synchronization;
    onLaunch: (
        minCompleteness: number | null,
        filterAttribute: string | null
    ) => void;
    onSchedule: (
        frequency: string,
        filterAttribute: string | null,
        importSecondaryLocales: boolean | null,
        importMarketsPricing: boolean | null,
        importPreExistingProductsOnly: boolean | null,
        importAssociations: boolean | null
    ) => void;
    onStop: (id: string, scope: SynchronizationScope) => void;
    filterPimAttributes: FilterPimAttribute[];
    isPreExistingCatalogMappingConfigured: boolean;
    isProductMediaMappingEmpty: boolean;
    isMetafieldAttributeMappingEmpty: boolean;
    isMetafieldAssociationMappingEmpty: boolean;
    isSecondaryLocalesMappingEmpty: boolean;
    isMarketsPricingMappingEmpty: boolean;
};

export const SynchronizationItem = ({
    synchronization,
    onLaunch,
    onSchedule,
    onStop,
    filterPimAttributes,
    isPreExistingCatalogMappingConfigured,
    isProductMediaMappingEmpty,
    isMetafieldAttributeMappingEmpty,
    isMetafieldAssociationMappingEmpty,
    isSecondaryLocalesMappingEmpty,
    isMarketsPricingMappingEmpty,
}: Props) => {
    const { isFeatureEnabled } = useFeatureFlags();
    const [isLaunchNowModal, openLaunchNowModal, closeLaunchNowModal] =
        useBooleanState(false);
    const [
        isScheduledSyncModal,
        openScheduledSyncModal,
        closeScheduledSyncModal,
    ] = useBooleanState(false);
    const isSynchronizationRunning =
        synchronization.status === SynchronizationStatus.Pending ||
        synchronization.status === SynchronizationStatus.InProgress ||
        synchronization.status === SynchronizationStatus.Stopping;
    const filters = useSelector((state) => state.filters);

    return (
        <>
            <Table.Row data-testid={synchronization.scope}>
                <Table.Cell>
                    <IconContainer>
                        <SynchronizationScopeIcon
                            scope={synchronization.scope}
                        />
                    </IconContainer>
                    <SynchronizationScopeLabel
                        scope={synchronization.scope}
                        colored
                    />
                </Table.Cell>

                <Table.Cell>
                    <SynchronizationStatusBadge
                        status={synchronization.status}
                    />
                </Table.Cell>

                {isSynchronizationRunning && synchronization.createdAt ? (
                    <>
                        <Table.Cell>
                            <SynchronizationDuration
                                createdAt={synchronization.createdAt}
                            />
                        </Table.Cell>

                        <Table.Cell>
                            <SynchronizationTypeLabel
                                type={synchronization.type}
                            />
                        </Table.Cell>

                        <Table.Cell>
                            {synchronization.type ===
                                SynchronizationType.Differential &&
                                DateTime.fromISO(
                                    synchronization.referenceDate
                                ).toLocaleString(DateTime.DATE_SHORT)}
                        </Table.Cell>
                        <Table.Cell>
                            {synchronization.scheduledAt ? (
                                DateTime.fromISO(
                                    synchronization.scheduledAt
                                ).toLocaleString(DateTime.DATE_SHORT)
                            ) : (
                                <Badge level='tertiary'>
                                    <FormattedMessage
                                        id='hkIfkj'
                                        defaultMessage='disabled'
                                    />
                                </Badge>
                            )}
                        </Table.Cell>
                        <Table.Cell>
                            <ButtonContainer>
                                <ButtonStyled
                                    ghost
                                    level='danger'
                                    onClick={() =>
                                        onStop(
                                            synchronization.id,
                                            synchronization.scope
                                        )
                                    }
                                    disabled={
                                        synchronization.status ===
                                        SynchronizationStatus.Stopping
                                    }
                                >
                                    <FormattedMessage
                                        id='q/uwLT'
                                        defaultMessage='Stop'
                                    />
                                </ButtonStyled>
                                <ButtonStyled
                                    ghost
                                    level='secondary'
                                    onClick={openScheduledSyncModal}
                                >
                                    <FormattedMessage
                                        id='JjaiHB'
                                        defaultMessage='Schedule sync'
                                    />
                                </ButtonStyled>
                            </ButtonContainer>
                        </Table.Cell>
                    </>
                ) : (
                    <>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                            {synchronization.scheduledAt ? (
                                DateTime.fromISO(
                                    synchronization.scheduledAt
                                ).toLocaleString(DateTime.DATE_SHORT)
                            ) : (
                                <Badge level='tertiary'>
                                    <FormattedMessage
                                        id='hkIfkj'
                                        defaultMessage='disabled'
                                    />
                                </Badge>
                            )}
                        </Table.Cell>
                        <Table.Cell>
                            <ButtonContainer>
                                <ButtonStyled
                                    ghost
                                    level='tertiary'
                                    onClick={openLaunchNowModal}
                                    disabled={
                                        !isFeatureEnabled(
                                            'enable_synchronization'
                                        ) ||
                                        synchronization.status ===
                                            SynchronizationStatus.Disabled
                                    }
                                >
                                    <FormattedMessage
                                        id='zFFdYx'
                                        defaultMessage='Manual sync'
                                    />
                                </ButtonStyled>
                                <ButtonStyled
                                    ghost
                                    level='secondary'
                                    onClick={openScheduledSyncModal}
                                    disabled={
                                        !isFeatureEnabled(
                                            'enable_synchronization'
                                        )
                                    }
                                >
                                    <FormattedMessage
                                        id='JjaiHB'
                                        defaultMessage='Schedule sync'
                                    />
                                </ButtonStyled>
                            </ButtonContainer>
                        </Table.Cell>
                    </>
                )}
            </Table.Row>
            {isLaunchNowModal && (
                <SynchronizationModal
                    closeModal={closeLaunchNowModal}
                    onLaunch={onLaunch}
                    scope={synchronization.scope}
                    filterPimAttributes={filterPimAttributes}
                    isPreExistingCatalogMappingConfigured={
                        isPreExistingCatalogMappingConfigured
                    }
                    isProductMediaMappingEmpty={isProductMediaMappingEmpty}
                    isMetafieldAttributeMappingEmpty={
                        isMetafieldAttributeMappingEmpty
                    }
                    isMetafieldAssociationMappingEmpty={
                        isMetafieldAssociationMappingEmpty
                    }
                    isSecondaryLocalesMappingEmpty={
                        isSecondaryLocalesMappingEmpty
                    }
                    isMarketsPricingMappingEmpty={isMarketsPricingMappingEmpty}
                />
            )}
            {isScheduledSyncModal && (
                <ScheduledSyncConfigModal
                    closeModal={closeScheduledSyncModal}
                    onSchedule={onSchedule}
                    scope={synchronization.scope}
                    initialFrequency={synchronization.frequency}
                    initialFilterAttribute={synchronization.filterAttribute}
                    initialImportSecondaryLocales={
                        synchronization.importSecondaryLocales
                    }
                    initialImportMarketsPricing={
                        synchronization.importMarketsPricing
                    }
                    initialAssociations={filters.associations}
                    filterPimAttributes={filterPimAttributes}
                    initialPreExistingProductsOnly={
                        synchronization.importPreExistingProductsOnly
                    }
                    isPreExistingCatalogMappingConfigured={
                        isPreExistingCatalogMappingConfigured
                    }
                    isSecondaryLocalesMappingEmpty={
                        isSecondaryLocalesMappingEmpty
                    }
                    isMarketsPricingMappingEmpty={isMarketsPricingMappingEmpty}
                    isMetafieldAssociationMappingEmpty={
                        isMetafieldAssociationMappingEmpty
                    }
                />
            )}
        </>
    );
};
